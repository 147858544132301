.btn-primary {
    background: #0b435f !important;
    font-family: 'SFProDisplayBold' !important;
    font-weight: normal !important;
    color: #FFFFFF !important;

    &:hover {
        background-color: rgba(9, 66, 94, 0.85) !important;
    }
}


.export-btn {
    padding: 8px 17px;
    background: #EFAB3F;
    border-radius: 24px;
    font-style: normal;
    font-family: 'SFProDisplayBold';
    font-weight: normal !important;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;

    &:hover {
        color: #FFFFFF;
    }
}

.download-btn {
    padding: 8px 17px;
    background: #5cad8d;
    border-radius: 24px;
    font-style: normal;
    font-family: 'SFProDisplayBold';
    font-weight: normal !important;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;

    &:hover {
        color: #FFFFFF;
    }
}