.card {
    margin: 0;
    box-shadow: 0 0px 8px 1px rgba(0, 0, 0, 0.05);
    border: 1px solid $border;
    color: #555;
  
    .card-header {
      font-family: 'SFProDisplayBold';
      border-bottom: 1px solid $border;
      color: #303030;
      font-family: 'SFProDisplayBold';
      font-size: 16px;
      line-height: 24px;
    }
  
    .card-body:not(.unset-card-body) {
      background-color: white;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      padding: 10px;
    }
  
    .card {
      border: 1px solid #efefef;
    }
  }