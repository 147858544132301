.alert{
    .material-icons{
        float: left;
        margin-top: -2px;
        padding-right: 10px;
    }
}


.alert-danger {
    font-size: 12px;
    width: 100%;
    color: #813838;
    background-color: #fee2e1;
    padding: 5px 10px;
    border-radius:  5px;
  }
  