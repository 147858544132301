@font-face {
  font-family: "SFProDisplayRegular";
  src: url("~src/assets/fonts/sfprodisplayregular/SFProDisplayRegular.eot");
  src: url("~src/assets/fonts/sfprodisplayregular/SFProDisplayRegular.eot")
      format("embedded-opentype"),
    url("~src/assets/fonts/sfprodisplayregular/SFProDisplayRegular.woff2")
      format("woff2"),
    url("~src/assets/fonts/sfprodisplayregular/SFProDisplayRegular.woff")
      format("woff"),
    url("~src/assets/fonts/sfprodisplayregular/SFProDisplayRegular.ttf")
      format("truetype"),
    url("~src/assets/fonts/sfprodisplayregular/SFProDisplayRegular.svg#SFProDisplayRegular")
      format("svg");
}

@font-face {
  font-family: "GESSTextMedium";
  src: url("~src/assets/fonts/gess/GESSTextMedium.eot");
  src: url("~src/assets/fonts/gess/GESSTextMedium.eot")
      format("embedded-opentype"),
    url("~src/assets/fonts/gess/GESSTextMedium.woff2") format("woff2"),
    url("~src/assets/fonts/gess/GESSTextMedium.woff") format("woff"),
    url("~src/assets/fonts/gess/GESSTextMedium.ttf") format("truetype"),
    url("~src/assets/fonts/gess/GESSTextMedium.svg#GESSTextMedium")
      format("svg");
}

@font-face {
  font-family: "dinnextltw23medium";
  src: url("~src/assets/fonts/dinn/dinnextltw23medium.eot");
  src: url("~src/assets/fonts/dinn/dinnextltw23medium.eot")
      format("embedded-opentype"),
    url("~src/assets/fonts/dinn/dinnextltw23medium.woff2") format("woff2"),
    url("~src/assets/fonts/dinn/dinnextltw23medium.woff") format("woff"),
    url("~src/assets/fonts/dinn/dinnextltw23medium.ttf") format("truetype"),
    url("~src/assets/fonts/dinn/dinnextltw23medium.svg#dinnextltw23medium")
      format("svg");
}

.carousel-control-prev,
.carousel-control-next {
  z-index: 999 !important;
}

.picsum-img-wrapper {
  position: relative;
}
.gc-amount {
  position: absolute;
  top: 4%;
  right: 5%;
  direction: rtl;
  font-family: "GESSTextMedium" !important;
  font-size: 24px;
  font-weight: bold;
  span {
    font-size: 12px;
    font-family: "DINNEXTLTW23LIGHT" !important;
  }
}

.gc-amount-en {
  position: absolute;
  top: 12%;
  right: 5%;
  font-size: 22px;
  font-weight: bold;
  span {
    font-size: 12px;
  
  }
}

.align-bottom {
  position: absolute;
  right: 5%;
  left: auto;
  bottom: 7px;
  @media screen and (min-width: 460px) {
    bottom: 40px;
  }

  .message {
    padding: 17px 10px;
    font-size: 8px;
    direction: rtl;
    max-width: 100%;
    height: 50px;
  }
}

.align-right {
  position: absolute;
  right: 4%;
  left: auto;
  bottom: auto;
  top: 20%;

  .message {
    padding: 10px;
    font-size: 8px;
    direction: rtl;
    height: auto;
    max-width: 127px;
    @media screen and (min-width: 460px) {
      max-width: 100%;
    }
  }
}

.align-left{
  position: absolute;
  left: 4%;
  right: auto;
  bottom: auto;
  top: 20%;

  .message {
    padding: 10px;
    font-size: 8px;
    direction: rtl;
    height: auto;
    max-width: 127px;
    @media screen and (min-width: 460px) {
      max-width: 100%;
    }
  }
}

.align-top {
  position: absolute;
  bottom: auto;
  top: 20%;
  .message {
    padding: 10px;
    font-size: 8px;
    direction: rtl;
    height: auto;
  }
}
