.title {
  font-size: 16px;
  font-family: 'SFProDisplayBold';
  color: #303030;
  margin-bottom: 16px !important;
  background-color: #fff;
  border-bottom: 1px solid #E5E5E5;
  padding: 16px !important;

}


.label {
  font-size: 14px;
  line-height: 16px;
  color: #303030;
}

.required-label,
.mat-mdc-form-field-required-marker {
  color: red;
  margin: 0 5px
}

.rouded-item {
  border-radius: 20px !important;
}

.example-chip-list {
  width: 100%;
}

.mat-mdc-card {
  padding: 16px;
  border-radius: 4px;
}