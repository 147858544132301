.form-control:not(.unset-form-control) {
  display         : block !important;
  width           : 100% !important;
  padding         : .375rem .75rem !important;
  font-size       : 14px;
  line-height     : 1.5;
  color           : #777;
  background-color: #fff;
  background-clip : padding-box;
  border          : 1px solid $gray2;
  border-radius   : 5px;
  transition      : border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  &:focus {
    border-color: #66afe9;
    outline     : 0;
    box-shadow  : inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
  }

  &.is-invalid {
    border-right: 5px solid #f86c6b;
  }

  &::placeholder {
    font-size: $placeholder-font;
    color    : $placeholder-color;
  }
}
