/* You can add global styles to this file, and also import other style files */
@import "../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css";
//import from modules
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
// import bootstrap-icons
@import "~bootstrap-icons/font/bootstrap-icons.css";
//import from our scss folder 7-1 stratege
@import "./scss/abstracts/variables";
// Temporary workaround to set bootstrap primary color, should handle the whole project theming
$primary: $color1;
@import "bootstrap";
@import "./scss/base/reset";
@import "./scss/components/input";
@import "./scss/components/button";
@import "./scss/layout/grid";
@import "./scss/custom.scss";
@import "../node_modules/ngx-toastr/toastr.css";
@import "styles/components/index.scss";
@import "./scss/_alert.scss";
@import "./scss/_tables.scss";
@import "./scss/_tables.scss";
@import "./scss/_reuseable_component.scss";
@import "./scss/_listscreen_header.scss";
@import "./scss/_cards.scss";
@import "./scss/_fonts.scss";
@import "./scss/_buttons.scss";
@import "./scss/giftcard-carousel.scss";
@import "./scss/rtl_direction.scss";
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

body {
  font-family: "SFProDisplayRegular" !important;
}

.ngx-toastr {
  border-radius: 20px 0px 20px 0px !important;
}

body:not(.unset-text-capitalize) {
  text-transform: capitalize;
}

input {
  text-transform: none;
}

// Selectbox style

.select-css {
  border-radius: 5px;
  border: 1px solid #0c425d;
  display: block;
  padding: 0.3em 1.4em 0.3em 0.8em;
  width: 98%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  box-shadow: none;
  font-size: 14px;
  -webkit-appearance: none;
  background-color: #fff;
  background-image: url("assets/images/arrow.png");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

/* Hide arrow icon in IE browsers */
.select-css::-ms-expand {
  display: none;
}

/* Hover style */
.select-css:hover {
  border-color: #888;
}

/* Focus style */
.select-css:focus {
  border-color: #aaa;
  /* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}

/* Set options to normal weight */
.select-css option {
  font-weight: normal;
}

/* Support for rtl text, explicit support for Arabic and Hebrew */
*[dir="rtl"] .select-css,
:root:lang(ar) .select-css,
:root:lang(iw) .select-css {
  background-position: left 0.7em top 50%, 0 0;
  padding: 0.6em 0.8em 0.5em 1.4em;
}

/* Disabled styles */
.select-css:disabled,
.select-css[aria-disabled="true"] {
  color: graytext;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
    linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
}

.select-css:disabled:hover,
.select-css[aria-disabled="true"] {
  border-color: #aaa;
}

.order-container {
  .chart-legend {
    position: absolute !important;
    right: -31px !important;
    top: 36px !important;

    @media (max-width: 360px) {
      position: absolute !important;
      right: 50px !important;
      top: 56px !important;
    }

    @media (max-width: 414px) and (min-width: 361px) {
      position: absolute !important;
      right: 20px !important;
      top: 56px !important;
    }
  }
}

.toast-message {
  text-transform: initial !important;
}

app-line-chart {
  margin-left: -25px;
}

g.cell foreignObject p {
  line-height: 40px !important;
}

.card-container {
  .description {
    right: 20px !important;
  }
}

.c-donutchart__legends {
  display: none !important;
}

// Sigma loading states styles
.mat-mdc-progress-spinner circle,
.mat-mdc-progress-spinner circle {
  stroke: $color1;
}

.sigma-loading-states-section__error-actions {
  button {
    background-color: $color1 !important;
  }
}

// ngx-bootstrap Pager
.pager a {
  font-weight: normal;
}

// Workaround to change material fields color until fixing the whole project theming

.mat-focused .mat-mdc-floating-label {
  /*change color of label*/
  color: $color1 !important;
}

.mat-mdc-form-field-ripple {
  /*change color of underline when focused*/
  background-color: $color1 !important;
}

.mat-mdc-form-field.mat-mdc-focused.mat-mdc-primary .mat-mdc-select-arrow {
  color: $color1;
}

.mat-mdc-primary .mat-mdc-option.mat-mdc-selected:not(.mat-mdc-option-disabled) {
  color: $color1;
}

.mat-ink-bar {
  background-color: $color1 !important;
  height: 4px;
}

app-validation-errors {
  display: block;
  height: 20px;
}

.old-search {
  margin-left: 20px;
  margin-bottom: 10px;

  .search-container {
    margin-left: 0px;
    margin-bottom: 10px;
  }
}

// Pie chart in dashboard
.total-pie-chart {
  position: absolute;
  top: 137px;
  left: 230px;

  @media (min-width: 1920px) {
    top: 137px;
    left: 230px;
  }

  @media only screen and (min-width: 1441px) and (max-width: 1600px) {
    top: 142px;
    left: 120px;
  }

  @media only screen and (min-width: 1401px) and (max-width: 1440px) {
    top: 136px;
    left: 117px;
  }

  @media only screen and (min-width: 1367px) and (max-width: 1400px) {
    top: 108px;
    left: 86px;
  }

  @media only screen and (min-width: 1361px) and (max-width: 1366px) {
    top: 130px;
    left: 110px;
  }

  @media only screen and (min-width: 1281px) and (max-width: 1360px) {
    top: 130px;
    left: 110px;
  }

  @media only screen and (min-width: 1153px) and (max-width: 1280px) {
    top: 130px;
    left: 110px;
  }

  @media only screen and (min-width: 1025px) and (max-width: 1152px) {
    top: 113px;
    left: 85px;
  }

  @media only screen and (min-width: 801px) and (max-width: 1024px) {
    top: 113px;
    left: 74px;
  }

  @media (max-width: 800px) {
    top: 66px;
    left: 42px;
  }

  .p-total {
    font-family: "SFProDisplayBold";
    font-size: 24px;
    color: #303030;
    text-align: center;
  }

  .p-label {
    font-weight: Regular;
    font-size: 16px;
    color: #838383;
    text-align: center;
  }
}

.total-pie-chart2 {
  position: absolute;
  top: 55px;
  left: 53px;

  @media (max-width: 799px) {
    top: 46px;
    left: 4px;
  }

  @media only screen and (min-width: 800px) and (max-width: 1023px) {
    top: 46px;
    left: 4px;
  }

  @media only screen and (min-width: 1024px) and (max-width: 1151px) {
    top: 46px;
    left: 20px;
  }

  @media only screen and (min-width: 1152px) and (max-width: 1279px) {
    top: 55px;
    left: 30px;
  }

  @media only screen and (min-width: 1280px) and (max-width: 1359px) {
    top: 55px;
    left: 35px;
  }

  @media only screen and (min-width: 1360px) and (max-width: 1365px) {
    top: 65px;
    left: 45px;
  }

  @media only screen and (min-width: 1366px) and (max-width: 1399px) {
    top: 65px;
    left: 45px;
  }

  @media only screen and (min-width: 1400px) and (max-width: 1599px) {
    top: 52px;
    left: 31px;
  }

  @media only screen and (min-width: 1400px) and (max-width: 1599px) {
    top: 53px;
    left: 29px;
  }

  .p-total {
    font-family: "SFProDisplayBold";
    font-size: 20px;
    color: #303030;
    text-align: center;
  }

  .p-label {
    font-weight: Regular;
    font-size: 14px;
    color: #838383;
    text-align: center;
  }
}

.imgborder {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 3px;
  box-shadow: 0px 6px 13px #ccc;
}

.table th,
.table td {
  vertical-align: middle;
}

.mat-mdc-form-field {
  min-width: unset !important;
  width: auto;
}

.action-icons {
  li {
    list-style: none;
    color: #0b435f;
    cursor: pointer;
    text-decoration: none;
    padding-bottom: 5px;
    display: inline-block;
    margin-right: 8px;

    &:hover {
      text-decoration: underline;
    }
  }

  li.vertical {
    display: block;
  }

  .delete {
    color: #fe5c60;
  }
}

.action {
  li {
    list-style: none;
    color: #0b435f;
    cursor: pointer;
    text-decoration: none;
    padding-bottom: 5px;
    display: inline-block;
    margin-right: 20px;

    &:hover {
      text-decoration: underline;
    }
  }

  li.vertical {
    display: block;
  }

  .delete {
    color: #fe5c60;

    &:hover {
      text-decoration: underline;
    }
  }
}

.total-data-list {
  background: #0b435f;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  margin-left: 5px;
  padding: 3px 14px;
  font-weight: normal;
  font-family: "SFProDisplayRegular" !important;
}

.small-padding {
  padding: 0 0.75rem 0 !important;
}

.status-lablel {
  padding: 1px;
  width: 110px;
}

.card {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;

  .card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
}

.mdc-dialog__container {
  padding: 0 !important;
  width: 450px !important;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem !important;
}

.card-title {
  margin-bottom: 0.75rem;
  font-size: 18px;
  color: #000;
}

.rouded-item {
  width: min-content !important;
}

.width100 {
  width: 100%;
}

//   mada rebrand
.wpwl-form-card {
  min-height: 0px !important;
}

.wpwl-label-brand {
  display: none !important;
}

.wpwl-control-brand {
  display: none !important;
}

.small-container {
  max-width: 768px;
}

.remove-btn {
  width: 35px;
  height: 30px;
  margin-top: 12px;
}

.alert-info {
  text-transform: initial;
}

.wpwl-apple-pay-button {
  font-size: 16px !important;
  display: block !important;
  width: 100% !important;
  height: 100px !important;
  -webkit-appearance: -apple-pay-button !important;
  -apple-pay-button-type: order !important;
}


.modal-geolocation {
  .modal-header {
    color: #000;
    letter-spacing: 0px;
    border: 0;

    h4 {
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      padding-top: 10px;
    }
  }

  .close {
    opacity: 1;

    span {
      font-size: 20px;
      color: #000;
      border: 2px solid #000;
      opacity: 1;
      border-radius: 50%;
      height: 25px;
      width: 25px;
      display: block;
    }
  }

  .modal-body {
    color: #000;

    label {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      padding: 10px 0;
    }

    textarea {
      height: 100px;
      border-radius: 5px;
      border: 1px solid #ccc;
      padding: 10px;
      width: 100%;
      resize: none;
    }

    .grid-right {
      padding-top: 20px;

      button {
        font-size: 16px;
        width: 137px;
        height: 40px;
      }
    }
  }
}