
  .state{
    background: #E9F7FF;
    border: 1px solid #09425E;
    box-sizing: border-box;
    border-radius: 20px;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #09425E;
    float: left;
    padding: 4px 12px;
    margin-right: 16px;
  
    @media(max-width: 800px) {
        margin-bottom: 5px;
        width: 45% !important;
    }
  }


  .page-icon{
    float: left;
    background: #4a9ac0;
    text-align: center;
    color: #fff;
    border-radius: 2px;
    width: 24px;
    height: 24px;
    margin-right: 5px;

    .material-icons{
        font-size: 18px;
        margin-top: 3px;
    }
  }


 