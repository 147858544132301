body {
  background-color: $main-background-color;
}

::-webkit-scrollbar {
  width: 10px;
  cursor: pointer;
}

/* Track */

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 8px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: $color2;
  border-radius: 8px;
  cursor: pointer;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: rgba($color2, 0.75);
  cursor: pointer;
}

.bs-datepicker-container {
  padding: 0;
}
.bs-datepicker-head {
  background-color: #0c425d !important;
}

.rtl {
  @import "styles-ar";
}

.ltr {
  @import "styles-en";
}
