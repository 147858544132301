@font-face {
    font-family: 'SFProDisplayRegular';
    src: url('~src/assets/fonts/sfprodisplayregular/SFProDisplayRegular.eot');
    src: url('~src/assets/fonts/sfprodisplayregular/SFProDisplayRegular.eot') format('embedded-opentype'),
         url('~src/assets/fonts/sfprodisplayregular/SFProDisplayRegular.woff2') format('woff2'),
         url('~src/assets/fonts/sfprodisplayregular/SFProDisplayRegular.woff') format('woff'),
         url('~src/assets/fonts/sfprodisplayregular/SFProDisplayRegular.ttf') format('truetype'),
         url('~src/assets/fonts/sfprodisplayregular/SFProDisplayRegular.svg#SFProDisplayRegular') format('svg');
}

@font-face {
    font-family: 'SFProDisplayBold';
    src: url('~src/assets/fonts/sfprodisplaybold/SFProDisplayBold.eot');
    src: url('~src/assets/fonts/sfprodisplaybold/SFProDisplayBold.eot') format('embedded-opentype'),
         url('~src/assets/fonts/sfprodisplaybold/SFProDisplayBold.woff2') format('woff2'),
         url('~src/assets/fonts/sfprodisplaybold/SFProDisplayBold.woff') format('woff'),
         url('~src/assets/fonts/sfprodisplaybold/SFProDisplayBold.ttf') format('truetype'),
         url('~src/assets/fonts/sfprodisplaybold/SFProDisplayBold.svg#SFProDisplayBold') format('svg');
}



@font-face {
    font-family: 'GESSTextMedium';
    src: url('~src/assets/fonts/gess/GESSTextMedium.eot');
    src: url('~src/assets/fonts/gess/GESSTextMedium.eot') format('embedded-opentype'),
         url('~src/assets/fonts/gess/GESSTextMedium.woff2') format('woff2'),
         url('~src/assets/fonts/gess/GESSTextMedium.woff') format('woff'),
         url('~src/assets/fonts/gess/GESSTextMedium.ttf') format('truetype'),
         url('~src/assets/fonts/gess/GESSTextMedium.svg#GESSTextMedium') format('svg');
}


@font-face {
    font-family: 'ArbFONTSGESSTextBold_26';
    src: url('~src/assets/fonts/gessbold/ArbFONTSGESSTextBold_26.eot');
    src: url('~src/assets/fonts/gessbold/ArbFONTSGESSTextBold_26.eot') format('embedded-opentype'),
         url('~src/assets/fonts/gessbold/ArbFONTSGESSTextBold_26.woff2') format('woff2'),
         url('~src/assets/fonts/gessbold/ArbFONTSGESSTextBold_26.woff') format('woff'),
         url('~src/assets/fonts/gessbold/ArbFONTSGESSTextBold_26.ttf') format('truetype'),
         url('~src/assets/fonts/gessbold/ArbFONTSGESSTextBold_26.svg#ArbFONTSGESSTextBold_26') format('svg');
}
