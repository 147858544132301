html,
body {
  direction: rtl;
}

.user-info {
  margin-right: auto;
  margin-left: 0;
}

.breadcrumb-item {
  padding-right: 0.5rem;
}

.breadcrumb-item+.breadcrumb-item::before {
  float: right;
}

.page-title-text {
  text-align: right;

  .page-icon {
    float: right;
  }
}

.text-right {
  text-align: left !important;
}

.card {
  text-align: right;

  .page-icon {
    float: right;
  }

  .state-class-ch2 {
    padding-right: 5px;
  }
}

.page-icon {
  margin-left: 5px;
}

// dashboard style

.dashboard-container {
  text-align: right;
}

.single_card_desc,
.total-text-ag {
  display: flex;
  justify-content: space-between;
}

.active-top5 {
  margin-left: 8px;
}

.prev-color,
.current-color,
.male-colors,
.female-colors,
.unknown-colors {
  margin-left: 5px;
}

.media-body {
  flex: 1;
  margin-right: 5px;
  display: flex;
  flex-direction: column;
}

.ngx-charts {
  direction: ltr;
}

.order-period {
  text-align: left !important;
}

.total-title {
  display: flex;
  flex-direction: column;
}

.total-text {
  display: flex;
  justify-content: space-between;
}

.loyal-dot {
  float: left;
}

.modal-body {
  direction: rtl;
  text-align: right;
}

button.close.pull-right.dashboard-modal-close-btn-ar {
  display: contents;
}

// add branch
.steps,
.label {
  text-align: right;
}

.grid-center-noGutter .content {
  text-align: right;
}

.foodics_logo {
  display: flex;
  justify-content: left;
}

.feature-list {
  background-position: right !important;
  padding-right: 30px;
}

// order preview

.grid-center-noGutter header {
  display: flex;
  justify-content: end;
}

.bg1 {
  display: flex;
  justify-content: center;
}

.divTableRow {
  float: left;
}

.grid-center .content {
  text-align: center;
}

//  profile page
.grid-middle-noGutter .icon {
  margin-left: 10px;
}

.browser-link {
  text-align: left;
}

//  some fixes after reviewing 
.modal-header .close {
  margin-left: 0;
}

.color-pallet-section {
  margin-right: 20px;
}

.textarea-emails::placeholder {
  text-align: left;
}

.pickup-cancel-btn {
  margin-right: 10px;
}

.divTableCell {
  direction: ltr;
}

.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-position: left calc(0.375em + 0.1875rem) center;
}

//  Vouchers page 
.grid-right-noGutter .color1 {
  margin-right: 5px;
}


.header-dropdown {
  right: auto !important;
  left: 0 !important;
}

.text-muted-direction {
  direction: ltr;
}

// color picker 
.color-picker {
  right: 67px !important;
  left: 0 !important;
}

.color-picker .arrow.arrow-right,
.color-picker .arrow.arrow-right-bottom,
.color-picker .arrow.arrow-bottom-right {
  transform: rotate(180deg);
  right: -20px !important;
  left: 0 !important;
}

.mat-mdc-radio-button {
  margin-right: 16px;
}

// arrow in arabic lang
.ar-arrow {
  transform: scaleX(-1);
}

.col-16.top-5-btns {
  display: flex;
  justify-content: right;
  align-items: center;
}

.alert-details {
  text-align: right;
}


.alerts-section {
  border-right: 1px solid #ddd;
}

.mat-mdc-menu-panel {
  direction: rtl;
}

@media (max-width:767px) {
  .alerts-section {
    border-right: 0 !important;
  }
}

mat-checkbox {
  padding-left: 20px;
  padding-right: 16px;
  width: 100%;

  .mdc-form-field {
    display: flex;
    justify-content: space-between;
  }
}

.quick-slert-show-all-btn {
  padding-right: 15px;
}

.show-all mat-icon {
  padding: 3px 0 0 3px;
  position: absolute;
  right: 67px;
  top: 6px;
}

.signup-title-ar {
  text-align: end !important;
  display: flex;
  align-items: end;
}

.lang {
  display: flex !important;
  justify-content: end !important;
  flex-direction: row-reverse !important;
}

.apple-wallet-alert .create-alert {
  margin: 0px 0px 0px 7px !important;
}

.nps-section-footer .nps-container .rating-result-content {
  text-align: right;
}

.searchPlaceholderInAr {
  ::placeholder {
    text-align: right;
  }
}