html,
body {
  direction: ltr;
}

.header-dropdown {
  right: 0 !important;
  left: auto !important;
}

.mat-mdc-radio-button {
  margin-left: 16px;
}

.alerts-section {
  border-left: 1px solid #ddd;
}

mat-checkbox {
  padding-left: 5px;
}